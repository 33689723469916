import {api} from '@/stores/api';
import {formatKeys} from '@/shared/utils/responseUtils';
import {
  ICommonArgs,
  ICommonResponse,
  IMeta,
  IMetaResponse,
  IPagination
} from '@/shared/models/commonModel';
import {
  IChat,
  IChatChannel,
  IChatChannelResponse,
  IChatGroupChannel,
  IChatGroupChannelResponse,
  IChatMessage,
  IChatMessageResponse,
  IChatResponse
} from '@/shared/models/chatModel';
import {latestChatMessageFields} from '@/shared/utils/requestParamsUtils';
import {IFile} from '@/shared/models/fileModel';

export interface IQueryArg extends ICommonArgs {
  userId?: string;
  filter?: string;
  page: {
    limit: number;
    offset: number;
  };
  counters?: {
    user_unread_chat_messages?: number;
    total_participants?: number;
  };
}

interface ICreateChatMessageArg {
  chatId: string;
  content: string;
  chat_message_reply?: {
    reply_chat_message_id: number;
  };
  attachments?: IFile[];
}

interface ICreateChatArg {
  company_id: number;
  telegram_invite_link?: string | null;
}

interface IUpdateChatArg {
  chatId: string;
  telegram_invite_link?: string | null;
}

export const apiWithChat = api.injectEndpoints({
  endpoints: builder => ({
    getChatGroupList: builder.query<IPagination<IChatGroupChannel[]>, IQueryArg>({
      query: params => ({
        url: '/v1/chat_groups/',
        method: 'get',
        params: {
          sort: 'user_active_chat_groups',
          include: 'latest_chat_message.user,latest_chat_message.customer',
          appends: 'latest_chat_message',
          fields: 'id,title,' + latestChatMessageFields,
          ...params
        }
      }),

      transformResponse: (
        result: ICommonResponse<IChatGroupChannelResponse[]>
      ): IPagination<IChatGroupChannel[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          links: result.links,
          data: result.data.map(item => {
            return formatKeys<IChatGroupChannelResponse, IChatGroupChannel>(item);
          })
        };
      },

      providesTags: result => {
        return result && result?.data
          ? [
              ...result.data.map(({id}) => ({type: 'CHAT_GROUP_LIST' as const, id})),
              {type: 'CHAT_GROUP_LIST', id: 'PARTIAL-LIST'}
            ]
          : [{type: 'CHAT_GROUP_LIST', id: 'PARTIAL-LIST'}];
      }
    }),
    getChatList: builder.query<IPagination<IChatChannel[]>, IQueryArg>({
      query: params => ({
        url: '/v1/chats/',
        method: 'get',
        params: {
          sort: 'user_active_chats',
          include: 'latest_chat_message,latest_chat_message.user,latest_chat_message.customer',
          fields: 'id,title,' + latestChatMessageFields,
          ...params
        }
      }),

      transformResponse: (
        result: ICommonResponse<IChatChannelResponse[]>
      ): IPagination<IChatChannel[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          links: result.links,
          data: result.data.map(item => {
            return formatKeys<IChatChannelResponse, IChatChannel>(item);
          })
        };
      },

      providesTags: result => {
        return result && result?.data
          ? [
              ...result.data.map(({id}) => ({type: 'CHAT_LIST' as const, id})),
              {type: 'CHAT_LIST', id: 'PARTIAL-LIST'}
            ]
          : [{type: 'CHAT_LIST', id: 'PARTIAL-LIST'}];
      }
    }),
    getChatById: builder.query<IChat, {chatId?: string}>({
      query: ({chatId}: {chatId: string}) => ({
        url: `/v1/chats/${chatId}`,
        method: 'get',
        params: {
          counters: {
            total_participants: 1
          },
          include: 'chat_users,chat_users.user,chat_customers,chat_customers.customer'
        }
      }),
      transformResponse: (result: {data: IChatResponse}): IChat | Promise<IChat> => {
        return formatKeys<IChatResponse, IChat>(result.data);
      },

      providesTags: (_result, _error, arg) => {
        return [{type: 'CHAT', id: arg?.chatId}];
      }
    }),
    createChatMessage: builder.mutation<ICommonResponse<IChat>, ICreateChatMessageArg>({
      query: ({chatId, content, attachments, chat_message_reply}) => ({
        url: `/v1/chats/${chatId}/chat_messages`,
        method: 'post',
        data: {
          content,
          chat_message_reply,
          attachments
        }
      })
      //
      // invalidatesTags: (_result, _error, arg) => {
      //   return [{type: 'CHAT', id: arg?.chatId}];
      // }
    }),
    getChatMessageList: builder.query<IPagination<IChatMessage[]>, {chatId: string}>({
      query: ({chatId, ...params}) => ({
        url: `/v1/chats/${chatId}/chat_messages`,
        method: 'get',
        params: {
          sort: '-created_at',
          include:
            'customer,user,files,chat_message_reply,chat_message_reply.reply_message,chat_message_reply.reply_message.customer,chat_message_reply.reply_message.user',
          ...params
        }
      }),
      transformResponse: (
        result: ICommonResponse<IChatMessageResponse[]>
      ): IPagination<IChatMessage[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          links: result.links,
          data: result.data.map(item => {
            return formatKeys<IChatMessageResponse, IChatMessage>(item);
          })
        };
      },
      providesTags: (result, _error) => {
        return result && result?.data
          ? [
              ...result.data.map(({id}) => ({type: 'CHAT_MESSAGE_LIST' as const, id})),
              {type: 'CHAT_MESSAGE_LIST', id: 'PARTIAL-LIST'}
            ]
          : [{type: 'CHAT_MESSAGE_LIST', id: 'PARTIAL-LIST'}];
      }
    }),
    createChat: builder.mutation<IChatResponse, ICreateChatArg>({
      query: (data: ICreateChatArg) => ({
        url: '/v1/chats/',
        method: 'post',
        data
      }),
      invalidatesTags: [{type: 'CHAT_LIST', id: 'PARTIAL-LIST'}]
    }),
    updateChat: builder.mutation<ICommonResponse<IChat>, IUpdateChatArg>({
      query: ({chatId, ...patch}) => ({
        url: `/v1/chats/${chatId}`,
        method: 'put',
        data: patch
      }),
      invalidatesTags: (_result, _error, {chatId}) => [
        {type: 'CHAT', id: chatId},
        {type: 'CHAT_LIST', id: 'PARTIAL-LIST'}
      ]
    })
  }),
  overrideExisting: true
});

export const {
  useGetChatListQuery,
  useGetChatGroupListQuery,
  useGetChatByIdQuery,
  useCreateChatMessageMutation,
  useGetChatMessageListQuery,
  useCreateChatMutation,
  useUpdateChatMutation
} = apiWithChat;
