import React, {FC, memo, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import cn from 'classnames';
import {Badge, Typography} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';

import {RoutePaths} from '@/shared/constants/route';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {logout} from '@/stores/AuthStore';
import {ReactComponent as LogoIcon} from '@/assets/logo.svg';
import {ReactComponent as TasksIcon} from '@/assets/nav-appeals.svg';
import {ReactComponent as ChatsIcon} from '@/assets/nav-chats.svg';
import {api} from '@/stores/api';
import {isSupportUserSelector, userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {TEMPLATE_DOC} from '@/shared/constants/links';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {useGetStatisticChatsQuery} from '@/stores/api/statistics.ts';
import {useStatisticChatsStreaming} from '@/shared/hooks/useStatisticChatsStreaming.ts';

import {version} from '../../../../../package.json';

import s from './NavMenu.module.css';

interface IProps {}

interface INavMenu {
  id: string;
  title: string;
  icon: React.ReactNode;
  path: RoutePaths;
}

const navMenuList: (isSupportUser?: boolean) => INavMenu[] = isSupportUser => [
  // {
  //   id: 'home',
  //   title: 'Главная',
  //   icon: <HomeIcon />,
  //   path: RoutePaths.Home
  // },
  {
    id: 'appeals',
    title: 'Задачи',
    icon: <TasksIcon />,
    path: isSupportUser ? RoutePaths.MainTechSupport : RoutePaths.Main
  },
  {
    id: 'chats',
    title: 'Чат',
    icon: <ChatsIcon />,
    path: RoutePaths.ChatGroups
  },
  {
    id: 'analytics',
    title: 'Аналитика',
    icon: <AssessmentIcon />,
    path: RoutePaths.Analytics
  }
];

export const NavMenu: FC<IProps> = () => {
  const currentUser = useAppSelector(userInfoSelector);
  const userName = formatUserName(currentUser);
  const isSupportUser = useAppSelector(isSupportUserSelector);

  const [isExpanded, setExpandValue] = useState(false);
  const dispatch = useAppDispatch();

  const handlerToggleMenu = () => {
    setExpandValue(!isExpanded);
  };

  const handleLogout = () => {
    dispatch(logout());

    localStorage.removeItem('persist:root');
    dispatch(api.util.resetApiState());
  };

  const {data: statisticChats} = useGetStatisticChatsQuery();

  useStatisticChatsStreaming();

  return (
    <div className={s.wrapper}>
      <div className={cn(s.nav, {[s.expanded]: isExpanded})}>
        <nav className={s.navPrimaryWrapper}>
          <Link to={RoutePaths.Main} className={s.navLogo}>
            <LogoIcon />
          </Link>

          <nav className={s.navList}>
            {navMenuList(isSupportUser).map(({id, title, icon, path}) => {
              let iconWithBadge = icon;

              if (
                id === 'chats' &&
                statisticChats?.chats?.unreadChatMessagesCount &&
                statisticChats?.chats?.unreadChatMessagesCount > 0
              ) {
                iconWithBadge = (
                  <Badge
                    badgeContent={statisticChats.chats.unreadChatMessagesCount}
                    color="primary"
                    sx={{
                      '& .MuiBadge-badge': {
                        fontSize: '10px'
                      }
                    }}
                  >
                    {icon}
                  </Badge>
                );
              }

              return (
                <NavLink
                  key={id}
                  to={path}
                  className={({isActive, isPending}) =>
                    cn(s.navLink, {[s.pending]: isPending, [s.active]: isActive})
                  }
                  title={title}
                >
                  <span className={s.icon}>{iconWithBadge}</span>
                  <span className={s.navContent}>{title}</span>
                </NavLink>
              );
            })}
          </nav>
        </nav>

        <div className={s.navSecondaryWrapper}>
          <div className={s.navList}>
            <Link to={TEMPLATE_DOC} className={s.navLink} title="Шаблоны" target="_blank">
              <span className={s.icon}>
                <DynamicFeedIcon />
              </span>
              <span className={s.navContent}>Шаблоны</span>
            </Link>
            <div className={s.userInfo}>
              <div className={s.userInfoImg}>
                <PersonOutlineIcon
                  sx={{
                    opacity: 0.6
                  }}
                />
              </div>
              <Typography
                variant="body2"
                color="white"
                sx={{
                  opacity: 0.6
                }}
                title={userName}
              >
                {currentUser?.email}
              </Typography>
            </div>
            <button className={s.navLink} onClick={handleLogout}>
              <span className={s.icon}>
                <LogoutIcon />
              </span>
              <span className={s.navContent}>Выйти</span>
            </button>
          </div>
          <button
            type="button"
            className={cn(s.navExpandLink, {[s.opened]: isExpanded})}
            onClick={handlerToggleMenu}
          >
            <span className={cn(s.iconArrow)} />
            <Typography
              variant="body2"
              color="white"
              textAlign="right"
              sx={{
                marginLeft: '60px',
                marginRight: '10px'
              }}
            >
              {version}
            </Typography>
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(NavMenu) as typeof NavMenu;
