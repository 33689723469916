import React, {FC, memo, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';

import {ComboBox, Input} from '@/components/UIKit';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  taskCompanySelector,
  taskDepartmentSelector,
  taskUnitSelector,
  setDepartmentError,
  updateDepartment,
  updateType,
  updateUser
} from '@/stores/TaskEditStore';
import {useGetDepartmentsQuery} from '@/stores/api/filtered-dictionaries';
import {ISubtask} from '@/shared/models/subtaskModel';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {IDepartment} from '@/shared/models/dictionaryModel';
import {ISupportTask} from '@/shared/models/supportTaskModel';
import {defaultTaskTypeId} from '@/shared/constants/taskTypes';
import {prepareDepartment} from '@/shared/utils/departmentUtils';
import {useGetCompanyByIdQuery} from '@/stores/api/companies';

interface IProps {
  task?: ITask | ISubtask | ISupportTask;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChangeData: (params: Record<string, number | Record<string, string | boolean>>) => void;
}

const TaskDepartmentBox: FC<IProps> = ({task, isDisabled, isLoading, onChangeData}) => {
  const dispatch = useAppDispatch();
  const editedDepartment = useAppSelector(taskDepartmentSelector);
  const editedCompany = useAppSelector(taskCompanySelector);
  const editedUnit = useAppSelector(taskUnitSelector);
  const {departmentsIds: departmentsIds, typesIds: typesIds, unitsIds: unitsIds} = usePrepareDict();
  const [isValueChanged, setIsValueChanged] = React.useState(false);
  const [commentValue, setCommentValue] = useState('');

  const isSupportTask = task && 'supportTaskComments' in task;

  const {responsibleDepartmentIds} = useGetCompanyByIdQuery(
    {
      companyId: editedCompany?.id
    },
    {
      skip: !editedCompany?.id,
      selectFromResult: ({data}) => ({
        responsibleDepartmentIds: data?.companyResponsibles?.length
          ? data.companyResponsibles.map(({departmentId}) => departmentId).join(',')
          : ''
      })
    }
  );

  const departmentFilters = {
    has_units: editedUnit?.id,
    is_support: isSupportTask ? 1 : 0,
    is_without_support: !isSupportTask ? 1 : 0,
    is_disabled: 0
  };

  const {departments, isDepartmentsLoading} = useGetDepartmentsQuery(
    {
      filters: responsibleDepartmentIds
        ? {id: 'in|' + responsibleDepartmentIds, ...departmentFilters}
        : departmentFilters
    },
    {
      skip: !responsibleDepartmentIds,
      selectFromResult: ({data, isLoading}) => ({
        departments: data
          ? data
              .filter(o => o.id !== task?.departmentId)
              .map(val => prepareDepartment(val, unitsIds))
          : [],
        isDepartmentsLoading: isLoading
      })
    }
  );

  const initDepartment = useMemo(() => {
    /**
     * При инициализации подставляем отдел из полного списка
     * в отфильтрованной версии может не быть выбранного
     */
    if (!departmentsIds || !task?.departmentId) {
      return;
    }

    return departmentsIds[task.departmentId];
  }, [task?.departmentId, departmentsIds]);

  useEffect(() => {
    if (initDepartment) {
      dispatch(updateDepartment(initDepartment));
    }
  }, [dispatch, initDepartment]);

  const handleChangeDepartment = (value?: IDepartment) => {
    dispatch(updateDepartment(value));
    dispatch(updateUser());
    dispatch(updateType(typesIds[defaultTaskTypeId]));

    if (value) {
      value?.id !== task?.departmentId ? setIsValueChanged(true) : setIsValueChanged(false);

      dispatch(setDepartmentError(null));

      onChangeData({
        department_id: Number(value.id)
      });
    }
  };

  const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommentValue(e.target.value);

    onChangeData({
      task_comment: {
        content: e.target.value,
        is_change_department: true
      }
    });
  };

  return (
    <>
      <ComboBox<IDepartment>
        options={departments || []}
        variantInput="standard"
        fieldLabel="Отдел"
        loading={isLoading || isDepartmentsLoading}
        sx={{
          minWidth: '400px'
        }}
        disableClearable
        value={editedDepartment || null}
        // @ts-ignore
        onChange={(_, newValue?: IDepartment) => {
          handleChangeDepartment(newValue);
        }}
        placeholder="Выберите отдел"
        disabled={isDisabled}
        inputClassName={cn({'combo-box-changed': isValueChanged})}
      />
      {isValueChanged && (
        <Input
          placeholder="Комментарий при передаче в отдел"
          fullWidth
          autoComplete="off"
          value={commentValue}
          onChange={handleChangeComment}
          label="Комментарий при передаче"
          customRootStyles={{minWidth: '400px'}}
          InputProps={{rows: 3}}
          disabled={isDisabled}
          isMultiline={true}
        />
      )}
    </>
  );
};

export default memo(TaskDepartmentBox);
