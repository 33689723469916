import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {generatePath, useNavigate} from 'react-router';
import {Button, Collapse, List, Stack, Tooltip} from '@mui/material';
import {ExpandLess} from '@mui/icons-material';

import {useAppSelector} from '@/stores/hooks';
import {IQueryArg, useGetChatGroupListQuery, useGetChatListQuery} from '@/stores/api/chats';
import {ChatListItem} from '@/scenes/ChatsPage/components/ChatListItem';
import useLinearLoading from '@/shared/hooks/useLinearLoading';
import {SearchField} from '@/components/SearchField';
import {searchSelector} from '@/stores/SearchStateStore/SearchStateSelector';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants';
import {IChatChannel, IChatGroupChannel} from '@/shared/models/chatModel';
import {ISearchState} from '@/stores/SearchStateStore';
import {RoutePaths} from '@/shared/constants/route';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {ChatList} from '@/shared/styles/chatStyles';

interface IProps {
  chatGroupId?: string;
  chatId?: string;
  isFetchingMessages?: boolean;
}

const ChatListSection: FC<IProps> = ({chatGroupId, chatId, isFetchingMessages}) => {
  const navigate = useNavigate();
  const searchState = useAppSelector(searchSelector);
  const currentUser = useAppSelector(userInfoSelector);
  const [prevSearchState, setPrevSearchState] = useState<ISearchState | null>(searchState);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const [chatGroupList, setChatGroupList] = useState<IChatGroupChannel[]>([]);
  const [chatList, setChatList] = useState<IChatChannel[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedChatGroupId, setSelectedChatGroupId] = useState<string | undefined>(undefined);
  const [isOpenSubList, setIsOpenSubList] = useState(false);

  const chatGroupsQuery = useMemo(() => {
    if (searchState !== prevSearchState) {
      setPaginationModel({page: 0, pageSize: PAGE_SIZE});
    }

    const page = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page
    };

    const filters: {my_chat_groups: string; find_titles_and_messages?: string} = {
      my_chat_groups: String(currentUser.id)
    };

    if (searchState) {
      filters.find_titles_and_messages = searchState;
    }

    setPrevSearchState(searchState);

    const counters = {
      user_unread_chat_messages: currentUser.id
    };

    const params: IQueryArg = {
      page,
      filters,
      counters
    };

    return params;
  }, [currentUser, paginationModel.pageSize, paginationModel.page, searchState, prevSearchState]);

  const {
    data: chatGroupData,
    isLoading: chatGroupIsLoading,
    isFetching: chatGroupIsFetching,
    refetch: refetchChatGroupList
  } = useGetChatGroupListQuery(chatGroupsQuery, {
    skip: !chatGroupsQuery || !currentUser || isFetchingMessages,
    refetchOnMountOrArgChange: true,
    pollingInterval: 30000
  });

  const chatsQuery = useMemo(() => {
    const page = {
      limit: 0,
      offset: 0
    };

    // @ts-ignore
    const filters: {chat_group_id: string; is_notify: number} = {};
    filters.is_notify = 0;
    if (selectedChatGroupId) {
      filters.chat_group_id = selectedChatGroupId;
    }

    const counters = {
      user_unread_chat_messages: currentUser.id
    };

    const params: IQueryArg = {
      page,
      filters,
      counters
    };

    return params;
  }, [currentUser, selectedChatGroupId]);

  const {
    data: chatData,
    isLoading: chatIsLoading,
    isFetching: chatIsFetching,
    refetch: refetchChatList
  } = useGetChatListQuery(chatsQuery, {
    skip: !chatsQuery || !selectedChatGroupId || chatGroupIsFetching,
    refetchOnMountOrArgChange: true,
    pollingInterval: 30000
  });

  const listRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (
      listRef.current &&
      !chatGroupIsFetching &&
      !chatGroupIsLoading &&
      hasMore &&
      !isLoadingMore
    ) {
      const {scrollTop, scrollHeight, clientHeight} = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 0.5) {
        setIsLoadingMore(true);
        setPaginationModel(prev => ({
          ...prev,
          pageSize: prev.pageSize + PAGE_SIZE
        }));
      }
    }
  }, [listRef, hasMore, chatGroupIsFetching, chatGroupIsLoading, isLoadingMore]);

  const handleClickListItem = (
    newChatGroupId: string,
    newChatId?: string,
    isSubListClick = false
  ) => {
    if (!isSubListClick) {
      if (selectedChatGroupId === newChatGroupId && chatData?.data.length !== 1) {
        setIsOpenSubList(!isOpenSubList);
      } else {
        setIsOpenSubList(false);
      }
    }

    if (isSubListClick || (!chatId && chatData?.data.length === 1)) {
      if (!newChatId) {
        newChatId = String(chatData?.data[0].id);
      }

      const path = generatePath(RoutePaths.ChatPage, {
        chatGroupId: `${newChatGroupId}`,
        chatId: `${newChatId}`
      });
      navigate(path);
    } else if (!selectedChatGroupId) {
      const path = generatePath(RoutePaths.ChatGroupPage, {
        chatGroupId: `${newChatGroupId}`
      });
      navigate(path);
    }

    setSelectedChatGroupId(newChatGroupId);
  };

  useEffect(() => {
    const element = listRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (chatGroupData?.data) {
      setChatGroupList(chatGroupData.data);
      setHasMore(chatGroupData.data.length >= paginationModel.pageSize);
      setIsLoadingMore(false);
    }
  }, [chatGroupData, paginationModel.pageSize, chatGroupId]);

  useEffect(() => {
    if (chatData?.data) {
      setChatList(chatData.data);

      if (chatData.data.length === 1) {
        const path = generatePath(RoutePaths.ChatPage, {
          chatGroupId: `${selectedChatGroupId}`,
          chatId: `${chatData.data[0].id}`
        });
        navigate(path);
      } else {
        setIsOpenSubList(!chatIsLoading && !chatIsFetching);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData, chatIsLoading]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [searchState, prevSearchState]);

  useEffect(() => {
    setSelectedChatGroupId(chatGroupId);
  }, [chatGroupId]);

  useEffect(() => {
    if (!chatGroupIsFetching && !chatIsFetching && !isFetchingMessages) {
      refetchChatGroupList();
      refetchChatList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMessages]);

  useLinearLoading(
    isFetchingMessages ||
      chatGroupIsLoading ||
      chatGroupIsFetching ||
      chatIsLoading ||
      chatIsFetching ||
      isLoadingMore
  );

  return (
    <ChatList ref={listRef}>
      <SearchField
        isStartAdornment={false}
        customRootStyles={{
          padding: '3%',
          position: 'sticky',
          top: -1,
          zIndex: 1
        }}
      />
      {chatGroupList.map(chatGroup => (
        <React.Fragment key={chatGroup.id}>
          <ChatListItem
            title={chatGroup.title}
            content={chatGroup.latestChatMessage?.content}
            createdAt={chatGroup.latestChatMessage?.createdAt}
            user={chatGroup.latestChatMessage?.user || chatGroup.latestChatMessage?.customer}
            unreadCount={chatGroup.userUnreadChatMessagesCount}
            isHasFiles={chatGroup.latestChatMessage?.isHasFiles}
            isSelected={Number(chatGroupId) === chatGroup.id && !!chatId}
            onClick={() => handleClickListItem(String(chatGroup.id))}
          />
          <Collapse
            key={chatGroup.id}
            in={isOpenSubList && !chatIsLoading && Number(selectedChatGroupId) === chatGroup.id}
            timeout="auto"
            unmountOnExit
          >
            {chatList.map(chat => (
              <React.Fragment key={chat.id}>
                <List component="div" disablePadding sx={{pl: 8}}>
                  <ChatListItem
                    title={chat.title}
                    content={chat.latestChatMessage?.content}
                    createdAt={chat.latestChatMessage?.createdAt}
                    isTelegram={chat.isTelegram}
                    user={chat.latestChatMessage?.user || chat.latestChatMessage?.customer}
                    unreadCount={chat.userUnreadChatMessagesCount}
                    isHasFiles={chat.latestChatMessage?.isHasFiles}
                    isSelected={Number(chatId) === chat.id}
                    isSubItem={true}
                    onClick={() => handleClickListItem(String(chatGroup.id), String(chat.id), true)}
                  />
                </List>
              </React.Fragment>
            ))}
          </Collapse>
          {selectedChatGroupId === String(chatGroup.id) && isOpenSubList && (
            <Stack direction="column" alignItems="center" justifyContent="center">
              <Tooltip title={'Свернуть'}>
                <Button
                  variant="text"
                  color="inherit"
                  fullWidth
                  onClick={() => setIsOpenSubList(!isOpenSubList)}
                >
                  {<ExpandLess />}
                </Button>
              </Tooltip>
            </Stack>
          )}
        </React.Fragment>
      ))}
    </ChatList>
  );
};

export default ChatListSection;
