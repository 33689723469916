import {api} from '@/stores/api/index';
import {
  IDepartment,
  IDepartmentResponse,
  ISource,
  IStatus,
  IType,
  ITypeResponse,
  IUnit
} from '@/shared/models/dictionaryModel';
import {ICommonArgs, ICommonResponse} from '@/shared/models/commonModel';
import {formatKeys} from '@/shared/utils/responseUtils';

interface IQueryArg extends ICommonArgs {}

const defaultParams = {
  fields: 'id,name',
  sort: 'id'
};

/**
 * Duplicate dictionaries method for keeping clean unfiltered dicts
 */
export const apiWithFilteredDictionaries = api.injectEndpoints({
  endpoints: builder => ({
    getDepartments: builder.query<IDepartment[], IQueryArg>({
      query: params => ({
        url: '/v1/dictionaries/departments/',
        method: 'get',
        params: {
          include: 'unit_department',
          fields: defaultParams.fields + ',is_cross_unit',
          ...params
        }
      }),
      transformResponse(returnValue?: ICommonResponse<IDepartmentResponse[]>): IDepartment[] {
        if (!returnValue?.data?.length) {
          return [];
        }
        return returnValue.data.map((item: IDepartmentResponse) => {
          return formatKeys<IDepartmentResponse, IDepartment>(item);
        });
      },
      // @ts-ignore
      providesTags: result => {
        return result
          ? [
              ...result.map(({id}) => ({type: 'DEPARTMENTS', id})),
              {type: 'DEPARTMENTS', id: 'LIST'}
            ]
          : [{type: 'DEPARTMENTS', id: 'LIST'}];
      }
    }),
    getStatuses: builder.query<IStatus[], IQueryArg>({
      query: params => ({
        url: '/v1/dictionaries/task_statuses/',
        method: 'get',
        params: {
          ...defaultParams,
          ...params
        }
      }),
      transformResponse(returnValue?: {data: IStatus[]}): IStatus[] {
        return returnValue?.data || [];
      }
    }),
    getSources: builder.query<ISource[], IQueryArg>({
      query: params => ({
        url: '/v1/dictionaries/task_sources/',
        method: 'get',
        params: {
          ...defaultParams,
          ...params
        }
      }),
      transformResponse(returnValue?: {data: ISource[]}): ISource[] {
        return returnValue?.data || [];
      }
    }),
    getTypes: builder.query<IType[], IQueryArg>({
      query: params => ({
        url: '/v1/dictionaries/task_types/',
        method: 'get',
        params: {
          fields: 'id,name,is_subtask,is_task,is_support_task',
          ...params
        }
      }),
      transformResponse(returnValue?: ICommonResponse<ITypeResponse[]>): IType[] {
        if (!returnValue?.data?.length) {
          return [];
        }
        return returnValue.data.map((item: ITypeResponse) => {
          return formatKeys<ITypeResponse, IType>(item);
        });
      },
      // @ts-ignore
      providesTags: result => {
        return result
          ? [...result.map(({id}) => ({type: 'TASK_TYPES', id})), {type: 'TASK_TYPES', id: 'LIST'}]
          : [{type: 'TASK_TYPES', id: 'LIST'}];
      }
    }),
    getUnits: builder.query<IUnit[], IQueryArg>({
      query: params => ({
        url: '/v1/dictionaries/units/',
        method: 'get',
        params: {
          ...defaultParams,
          ...params
        }
      }),
      transformResponse(returnValue?: {data: IUnit[]}): IUnit[] {
        return returnValue?.data || [];
      },
      // @ts-ignore
      providesTags: result => {
        return result
          ? [...result.map(({id}) => ({type: 'UNITS', id})), {type: 'UNITS', id: 'LIST'}]
          : [{type: 'UNITS', id: 'LIST'}];
      }
    })
  }),
  overrideExisting: true
});

export const {
  useGetStatusesQuery,
  useGetDepartmentsQuery,
  useGetTypesQuery,
  useGetSourcesQuery,
  useGetUnitsQuery
} = apiWithFilteredDictionaries;
