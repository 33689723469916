import React, {forwardRef, useEffect, useMemo, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';
import {
  Box,
  Button,
  createFilterOptions,
  FilterOptionsState,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {addDays, format} from 'date-fns';

import {useGetTypesQuery} from '@/stores/api/filtered-dictionaries';
import {useCreateTaskMutation} from '@/stores/api/main-page/create-tasks';
import {FormComboBox, FormComboBoxWithConnect} from '@/components/Form/components/FormComboBox';
import {FormMultiCheckbox} from '@/components/Form/components/FormMultiCheckbox';
import {IOption} from '@/shared/models/tasksFilterModel';
import {ITaskResponse} from '@/shared/models/tasksDataModel';
import {IUser} from '@/shared/models';
import {AddFilesSection} from '@/components/AddFilesSection';
import {useFileState} from '@/shared/hooks/useFileState';
import {checkSize} from '@/shared/utils/fileUtils';
import useLoading from '@/shared/hooks/useLoading';
import {useDepartmentsLogic} from '@/modals/create/hooks/useDepartmentsLogic';
import {useAppSelector} from '@/stores/hooks';
import {OptionUser} from '@/components/UIKit/OptionUser';
import {useCompanyLogic} from '@/modals/create/hooks/useCompanyLogic';
import TextEditor from '@/components/TextEditor/TextEditor';
import {ICompany} from '@/shared/models/companyModel';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';
import {DateRangePicker} from '@/components/UIKit/DateRangePicker';
import {DelayTaskConfirmation} from '@/modals/confirmation/DelayTaskConfirmation';
import {useGetEmployeesQuery} from '@/stores/api/employees.ts';

import {FormValues, prepareRequestData} from './utils';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IProps {
  onClose: () => void;
}

interface ICheckboxOptions extends IOption {
  isDefault?: boolean;
  value: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 786,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 12,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const baseCheckboxOptions: ICheckboxOptions[] = [
  {
    id: 2,
    name: 'Отправить в мобильное приложение',
    value: 'isSendMobile',
    isDefault: false
  },
  {
    id: 1,
    name: 'Отправить письмом в почту',
    value: 'isSendMail',
    isDefault: true
  },
  {
    id: 5,
    name: 'Отправить в Bitrix24',
    value: 'isSendBitrix',
    isDefault: false
  },
  {
    id: 3,
    name: 'Не закрывать задачу после создания',
    value: 'dontClose',
    isDefault: false
  },
  {
    id: 4,
    name: 'Отложить до',
    value: 'delayTo',
    isDefault: false
  }
];

const CreateTask = forwardRef<HTMLInputElement, IProps>((props, _ref) => {
  const {onClose} = props;
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);
  const [isReady, setIsReady] = useState(false);

  /**
   * CREATE TASK SUBMIT
   */
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [submitCreateTask, {isLoading: isSubmitLoading, isError}] = useCreateTaskMutation();

  /**
   * ОТЛОЖИТЬ ДО
   */
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDateDelay, setSelectedDateDelay] = useState<Date | null>(null);
  const [isDelayConfirmationOpen, setIsDelayConfirmationOpen] = useState(false);
  const [reasonDelay, setReasonDelay] = useState<string>('');

  const handleDateDelayChange = (val: Value) => {
    const newDateDelay = Array.isArray(val) ? val[0] : val;
    if (setSelectedDateDelay && (newDateDelay instanceof Date || newDateDelay === null)) {
      setSelectedDateDelay(newDateDelay);
    }
    setIsDelayConfirmationOpen(true);
  };

  const defaultSenders = baseCheckboxOptions.filter(o => o.isDefault).map(o => o.value);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors},
    resetField
  } = useForm<FormValues>({
    defaultValues: {
      senders: defaultSenders
    }
  });

  const watchCompany = watch('company');
  const watchDepartment = watch('department');
  const watchSenders = watch('senders');

  /**
   * СПИСОК ОПЦИЙ
   */
  const checkboxOptions = useMemo(() => {
    let options = [...baseCheckboxOptions];

    // Фильтрация опций на основе watchSenders
    if (!watchSenders?.includes('isSendMail')) {
      options = options.filter(option => option.value !== 'dontClose');
    }

    if (!watchSenders?.includes('isSendMail') || !watchSenders?.includes('dontClose')) {
      options = options.filter(option => option.value !== 'delayTo');
    }

    if (!watchCompany?.bitrixApiId || watchCompany?.bitrixApiId === 1) {
      options = options.filter(option => option.value !== 'isSendBitrix');
    }

    // опция "Отложить до"
    const delayToOptionIndex = options.findIndex(option => option.value === 'delayTo');

    if (delayToOptionIndex !== -1) {
      // Если дата выбрана и опция "Отложить до" выбрана, обновляем name
      if (selectedDateDelay && watchSenders?.includes('delayTo')) {
        const formattedDate = format(selectedDateDelay, 'dd.MM.yyyy');
        options[delayToOptionIndex].name = `Отложить до ${formattedDate}`;
      } else {
        // Если дата не выбрана или опция "Отложить до" не выбрана, удаляем дату из name
        options[delayToOptionIndex].name = 'Отложить до';
        setSelectedDateDelay(null);
        setIsCalendarOpen(false);
      }
    }

    return options;
  }, [watchSenders, watchCompany, selectedDateDelay]);

  useEffect(() => {
    const isDelayToSelected = watchSenders?.includes('delayTo');
    if (isDelayToSelected) {
      setIsCalendarOpen(true);
    } else {
      setIsCalendarOpen(false);
    }
  }, [watchSenders, setValue]);

  /**
   *    Получаем Список типов
   *
   *  - Вставляем отдел в фильтр filters[outgoing_task_types] текущий выбранный отдел
   */

  const {types, isLoading: isTypeLoading} = useGetTypesQuery(
    {
      'filters[outgoing_task_types]': `${watchDepartment?.id}`
    },
    {
      skip: !watchDepartment?.id,
      selectFromResult: ({data, isLoading}) => ({
        types: data ? data.filter(val => val.isTask) : [],
        isLoading
      })
    }
  );

  const withMobile =
    watchSenders.includes('isSendMobile') ||
    (!watchSenders.includes('isSendMail') && !watchSenders?.includes('isSendBitrix'));

  /**
   * СПИСОК КЛИЕНТОВ
   */
  const {fetchCompanies, query, isCompaniesLoading} = useCompanyLogic({
    queryParams: {
      fields: 'bitrix_api_id',
      include: 'company_responsibles',
      'filters[is_has_employees]': 1
    }
  });

  const toBitrix = watchSenders?.includes('isSendBitrix') && watchCompany?.bitrixApiId;

  /**
   * СПИСОК СОТРУДНИКОВ
   */
  const {data: employees, isLoading: isLoadingList} = useGetEmployeesQuery(
    {
      filters: {
        company_id: watchCompany?.id,
        active: 1,
        bitrix_api_id: toBitrix ? watchCompany?.bitrixApiId : 1,
        // Если источник задачи выбран "Отправить в мобильное приложение",
        // в том числе и вместе с "Отправить письмом в почту",
        // в запросе получения компаний необходимо использовать
        // is_has_mobile = 1
        is_has_mobile: withMobile ? 1 : undefined,
        is_telegram: 0
      }
    },
    {
      skip: !watchCompany?.id,
      refetchOnMountOrArgChange: true
    }
  );

  // files
  const {files, handleDeleteFile, handleAttachFiles} = useFileState();
  const [errorFileSize, setErrorFileSize] = useState<string | null>(null);

  // @ts-ignore
  const onSubmit: SubmitHandler<FormValues | undefined> = async data => {
    if (Object.keys(errors).length > 0 || errorFileSize) {
      return;
    }

    if (data) {
      const requestData = {
        ...data,
        attachments: files,
        delayAt: selectedDateDelay?.toISOString()
      };

      const preparedData = prepareRequestData(requestData);

      if (reasonDelay.trim() !== '') {
        preparedData.delay_task_comment = {
          content: reasonDelay.trim()
        };
      } else {
        delete preparedData.delay_task_comment;
      }

      const result: {data: ITaskResponse} | {error: unknown} = await submitCreateTask(preparedData);

      if ('data' in result && result?.data) {
        onClose();
      }
    }
  };

  /**
   * СПИСОК ОТДЕЛОВ
   */

  const {departments, isDepartmentsLoading, isDepartmentsDisabled} = useDepartmentsLogic({
    company: watchCompany,
    isTask: true
  });

  /**
   * SENDERS
   */
  const handleSendersChange = (name: any, optionValue: string) => {
    const hasValue = watchSenders.includes(optionValue);

    if (hasValue) {
      const filteredSenders = watchSenders.filter((item: string) => item !== optionValue);

      if (
        !filteredSenders.includes('isSendMail') &&
        !filteredSenders.includes('isSendMobile') &&
        !filteredSenders.includes('isSendBitrix')
      ) {
        setValue(name, [...filteredSenders, ...defaultSenders]);
      } else {
        setValue(name, filteredSenders);
      }
    } else {
      if (optionValue === 'isSendBitrix') {
        setValue(name, ['isSendBitrix']);
      } else {
        const filteredSenders = watchSenders.filter((item: string) => item !== 'isSendBitrix');
        setValue(name, [...filteredSenders, optionValue]);
      }
    }
  };

  useEffect(() => {
    setErrorFileSize(null);
    const fileSizeError = checkSize(files, watchSenders?.includes('isSendMail'));
    setErrorFileSize(fileSizeError);
    setIsSubmitDisabled(!!fileSizeError);
  }, [files, watchSenders]);

  useEffect(() => {
    if (departments && isDepartmentsDisabled && !isReady) {
      resetField('department', {
        defaultValue: departments[0]
      });
      setIsReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments, isDepartmentsDisabled, resetField]);

  useEffect(() => {
    if (watchCompany || watchCompany === null) {
      resetField('employeeList');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCompany]);

  useEffect(() => {
    if (watchDepartment || watchDepartment === null) {
      resetField('taskType');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepartment]);

  useLoading(isSubmitLoading);

  const filter = createFilterOptions<IUser>({
    trim: true,
    stringify: option => option.name + option.email
  });

  const isIndividualCompany = useMemo(() => {
    return checkCompanyIndividual(
      companyIndividualType,
      watchCompany?.companyTypeId,
      watchCompany?.name
    );
  }, [companyIndividualType, watchCompany?.companyTypeId, watchCompany?.name]);

  return (
    <Box sx={style}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={12} sx={{marginBottom: 12}}>
          <Grid item sm={6} xs={12}>
            <label htmlFor="company">
              Клиент <span className="imp">*</span>
            </label>
            <FormComboBoxWithConnect
              id="company"
              name="company"
              required
              isLoading={isCompaniesLoading}
              control={control}
              placeholder="Выберите клиента"
              getData={fetchCompanies}
              query={query}
              renderOption={({className, ...props}: any, option: ICompany) => {
                const isImp = checkCompanyIndividual(
                  companyIndividualType,
                  option.companyTypeId,
                  option.name
                );
                return (
                  <li {...props} className={cn(className, isImp ? 'imp' : '')} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              inputClassName={isIndividualCompany ? 'imp' : ''}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="department">
              Отдел <span className="imp">*</span>
            </label>
            <FormComboBox
              id="department"
              name="department"
              required
              isDisabled={isDepartmentsDisabled}
              isLoading={isDepartmentsLoading}
              options={departments || []}
              control={control}
              placeholder="Выберите отдел"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormMultiCheckbox<ICheckboxOptions, FormValues>
              control={control}
              name="senders"
              options={checkboxOptions}
              disabledCheckboxes={[]}
              className="flex-column"
              onOptionChange={handleSendersChange}
            />
            {isCalendarOpen && (
              <DateRangePicker
                placeholder="Выберите дату"
                start={undefined}
                end={selectedDateDelay ? format(selectedDateDelay, 'yyyy-MM-dd') : undefined}
                isOpenStraightaway
                singleDate
                isDelayTask
                setIsCalendarOpen={setIsCalendarOpen}
                minDate={addDays(new Date(), 1)}
                handleDateDelayChange={handleDateDelayChange}
              />
            )}
            {isDelayConfirmationOpen && (
              <DelayTaskConfirmation
                open={isDelayConfirmationOpen}
                delayData={selectedDateDelay}
                setIsDelayConfirmationOpen={setIsDelayConfirmationOpen}
                reasonDelay={reasonDelay}
                setReasonDelay={setReasonDelay}
                isCreateTask
                setIsCalendarOpen={setIsCalendarOpen}
              />
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Stack justifyContent="flex-end" sx={{height: '100%'}}>
              <label htmlFor="taskType">
                Тип обращения <span className="imp">*</span>
              </label>
              <FormComboBox
                id="taskType"
                name="taskType"
                required
                options={types}
                isLoading={isTypeLoading}
                control={control}
                placeholder="Выберите тип обращения"
              />
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="employee">
              Сотрудник клиента <span className="imp">*</span>
            </label>
            <FormComboBox
              id="employeeList"
              name="employeeList"
              required
              multiple
              isLoading={isLoadingList}
              options={employees}
              control={control}
              placeholder="Выберите сотрудника клиента"
              disableCloseOnSelect={true}
              renderOption={(props: any, option: IUser, {selected}: {selected: boolean}) => {
                return (
                  <OptionUser
                    {...props}
                    multiple={true}
                    key={option.id}
                    option={option}
                    selected={selected}
                    isHasMobile={option.isHasMobile}
                    isSpam={option.isSpam}
                  />
                );
              }}
              filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
                return filter(options, params);
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="mailTemplate">Макет рассылки</label>
            <FormComboBox
              id="mailTemplate"
              name="mailTemplate"
              options={[]}
              isDisabled
              control={control}
              placeholder="По умолчанию"
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="title">
              Тема <span className="imp">*</span>
            </label>
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Обязательное поле'
                }
              }}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <TextField
                  id="title"
                  placeholder="Укажите название задачи"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  fullWidth
                  autoComplete="off"
                  error={!!errors?.title}
                  helperText={errors?.title?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="content">
              Описание задачи <span className="imp">*</span>
            </label>
            <Controller
              name="content"
              control={control}
              rules={{
                required: 'Обязательное поле',
                validate: value => {
                  const isNotEmpty = value.replace(/<[^>]*>/g, '').trim().length > 0;
                  return (
                    isNotEmpty ||
                    'Описание задачи не может быть пустым или состоять только из пробелов'
                  );
                }
              }}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <TextEditor
                  id="content"
                  value={value}
                  onChange={(value: string) => {
                    onChange(value);
                  }}
                  error={!!errors?.content}
                  helperText={errors?.content?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <AddFilesSection
              files={files}
              onDeleteFile={handleDeleteFile}
              onChangeFileInput={handleAttachFiles}
            />
            {errorFileSize && (
              <Typography variant="body2" color="error">
                {errorFileSize}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitLoading}
            loadingIndicator="Создание…"
            sx={{minWidth: 154}}
            disabled={isSubmitDisabled || isSubmitLoading || isError}
          >
            Создать
          </LoadingButton>
          {isError && <span className="error">Ошибка создания задачи</span>}
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{minWidth: 154, borderWidth: 2}}
          >
            Отмена
          </Button>
        </Grid>
      </form>
    </Box>
  );
});

CreateTask.displayName = 'CreateTask';

export default CreateTask;
